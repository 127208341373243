import React from 'react';
import '../App.css';

function Price() {
  return (
    <div className="price">
      <h1>Pricing Information</h1>
      <p>Details about pricing go here.</p>
    </div>
  );
}

export default Price;
