import React from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';

function Question3() {
    return (
        <div className="FAQ-container" >
          <h1 className="FAQ-title" style={{marginTop: '100px'}}>Frequently Asked Questions</h1>
          <p><span className="faq-titles" ><strong><Link style={{textDecoration: 'none',  color: 'rgb(255, 4, 58)'}} to="/faq">⬅ Go back to the FAQ page.</Link></strong></span></p>
          <div className="FAQ-questions" style={{maxWidth: '700px'}}>  
            <h2>Will this affect my ping?</h2>
            <p>
            No our VPN will not increase your ping. In fact, it will decrease it. We are re-routing your network traffic through the game server, which will result in a lower ping.
            </p>
          </div>
        </div>
      );
    }

export default Question3;