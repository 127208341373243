import React from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';

function Question2() {
    return (
        <div className="FAQ-container" >
          <h1 className="FAQ-title" style={{marginTop: '100px'}}>Frequently Asked Questions</h1>
          <p><span className="faq-titles" ><strong><Link style={{textDecoration: 'none',  color: 'rgb(255, 4, 58)'}} to="/faq">⬅ Go back to the FAQ page.</Link></strong></span></p>
          <div className="FAQ-questions" style={{maxWidth: '700px'}}>  
            <h2>I changed my location, but it doesn't change in game!</h2>
            <p>
            <strong>There could be a few reasons why that happened:</strong>
            <br /> <br />
            1. Make sure your Network IP matches the IP your console is connected too or PC.
            <br />
            2. Make sure you have the correct DNS settings.
            <br />
            3. Restart your console or PC.
            <br />
            4. Check if your PC settings is set to "Public Settings" you can find it in the network settings.
            </p>
          </div>
        </div>
      );
    }

export default Question2;