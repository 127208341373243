import React from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';

function Question4() {
    return (
        <div className="FAQ-container" >
          <h1 className="FAQ-title" style={{marginTop: '100px'}}>Frequently Asked Questions</h1>
          <p><span className="faq-titles" ><strong><Link style={{textDecoration: 'none',  color: 'rgb(255, 4, 58)'}} to="/faq">⬅ Go back to the FAQ page.</Link></strong></span></p>
          <div className="FAQ-questions" style={{maxWidth: '700px'}}>  
            <h2>Do i need any hardware to connect it to my console?</h2>
            <p>
                No, unlike any other VPN SweatDNS does not require any additional hardware to connect to your console.       
            </p>
          </div>
        </div>
      );
    }

export default Question4;