import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CouponManager = () => {
  const [coupons, setCoupons] = useState([]);
  const [newCouponCode, setNewCouponCode] = useState('');
  const [newCouponDiscount, setNewCouponDiscount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCoupons();
  }, []);

  const fetchCoupons = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://api.sweatdns.com/admin/coupons', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setCoupons(response.data);
    } catch (error) {
      console.error('Error fetching coupons:', error);
    }
    setLoading(false);
  };

  const handleCreateCoupon = async () => {
    try {
        const response = await axios.post('https://api.sweatdns.com/admin/coupons', {
            code: newCouponCode,
            discountPercentage: newCouponDiscount, 
          }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
      setCoupons([...coupons, response.data]);
      setNewCouponCode('');
      setNewCouponDiscount(0);
    } catch (error) {
      console.error('Error creating coupon:', error);
    }
  };

  const handleDeleteCoupon = async (couponId) => {
    try {
      await axios.delete(`https://api.sweatdns.com/admin/coupons/${couponId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setCoupons(coupons.filter(coupon => coupon._id !== couponId));
    } catch (error) {
      console.error('Error deleting coupon:', error);
    }
  };

  return (
    <div className="coupon-manager-container">
      <h2 className="coupon-manager-header">Coupon Manager</h2>
      {loading ? (
        <p>Loading coupons...</p>
      ) : (
        <ul className="coupon-list">
          {coupons.map(coupon => (
            <li key={coupon._id} className="coupon-item">
              <span className="coupon-code">Code: {coupon.code}</span>
              <span className="coupon-discount">Discount: {coupon.discountPercentage}%</span>
              <button
                className="delete-button"
                onClick={() => handleDeleteCoupon(coupon._id)}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      )}
      <div className="input-group">
        <input
          type="text"
          value={newCouponCode}
          onChange={(e) => setNewCouponCode(e.target.value)}
          placeholder="Coupon Code"
          className="coupon-input"
        />
        <input
          type="number"
          value={newCouponDiscount}
          onChange={(e) => setNewCouponDiscount(e.target.value)}
          placeholder="Discount Percentage"
          className="discount-input"
        />
        <button className="create-button" onClick={handleCreateCoupon}>
          Create Coupon
        </button>
      </div>
    </div>
    );
          };

export default CouponManager;
