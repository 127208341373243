import '../App.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import ErrorMessage from '../components/ErrorMessage';
import ErrorIcon from '../assets/img/warning.webp'; 
import SuccessMessage from '../components/SuccessMessage';

const Otp = () => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const uniqueCode = queryParams.get('code');

    if (!uniqueCode) {
      navigate('/');
      return;
    }

    checkVerificationStatus(uniqueCode);
  }, [navigate]);


  const checkVerificationStatus = async (uniqueCode) => {
    try {
      const response = await axios.get(`https://api.sweatdns.com/user/check-verification/${uniqueCode}`);
      console.log("Verification Status Response: ", response.data); 
      if (response.data.isVertificated) {
        navigate('/login');
      }
    } catch (error) {
      console.error("Error checking verification status", error);
    }
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setErrorMessage('');
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleSubmit = async () => {
    const otpCode = otp.join("");
    const queryParams = new URLSearchParams(window.location.search);
    const uniqueCode = queryParams.get('code');
  
    if (!uniqueCode) {
      setErrorMessage('No code provided.');
      return;
    }

    if (otpCode.length < 6) {
      setErrorMessage('Please enter the OTP code.');
      return;
    }

    try {
        const response = await axios.post('https://api.sweatdns.com/user/verify-otp', { uniqueCode, otp: otpCode });
        if (response.data.success) {
          setSuccessMessage('OTP verified successfully! Redirecting...'); 
          setTimeout(() => {
            navigate(`/login`);
          }, 3000);
        } else {
          setErrorMessage(response.data.message || 'Incorrect OTP, please try again.');
        }
      } catch (error) {
        console.error("Failed to verify OTP", error.response || error);
        setErrorMessage(error.response?.data?.message || 'An error occurred while verifying the OTP.'); 
      }
    };


  return (
    <div className="signup-container">
      <div className="login-form-container">
        <h2 className="signup-header">VERIFY YOUR <span className="highlight">SWEATDNS</span> ACCOUNT</h2>
        {errorMessage && <ErrorMessage message={errorMessage} bgColor='#ff043a' color= '#fff' image={ErrorIcon} />}
        {successMessage && <SuccessMessage message={successMessage} />}
        <p>Enter the OTP sent to your email:</p>
        <div className="otp-input-container">
          {otp.map((data, index) => {
            return (
              <input
                className="otp-input"
                type="text"
                name="otp"
                maxLength="1"
                key={index}
                value={data}
                onChange={e => handleChange(e.target, index)}
                onFocus={e => e.target.select()}
              />
            );
          })}
        </div>
        <button className="verify-otp-button" onClick={handleSubmit}>Verify OTP</button>
        <p className="signup-footer">Didn't receive the OTP? <span className="highlight"> <a className="opt-resend-link" style={{color: '#ff043a' }} href="https://api.sweatdns.com/user/otp-resend">Resend OTP</a></span></p> 
      </div>

    </div>
  );
};

export default Otp;
