import React from 'react';
import SuccessIcon from '../assets/img/activated.webp'; 

const SuccessMessage = ({ message }) => {
  return (
    <div style={{
      background: 'green', 
      color: '#fff',
      padding: '10px',
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center',
      gap: '10px', 
      margin: '10px 0',
    }}>
      <img src={SuccessIcon} alt="Success" style={{ width: '24px', height: '24px' }} />
      <span>{message}</span>
    </div>
  );
};

export default SuccessMessage;
