import React from 'react';
import FAQ from '../components/faq'; 
import { faqData } from './faqData'; 
import '../App.css';

const FAQPage = () => {
  console.log('FAQ Data:', faqData); 

  return (
    <div>
      <h1 className="faq-title-header">Frequently Asked Questions</h1>
      <FAQ faqs={faqData} />
    </div>
  );
};

export default FAQPage;