import React from "react";
import ProfileIcon from '../assets/img/profile-user.webp';
import { Link } from 'react-router-dom';

const ProfileBox = () => {
    return (
        <div className="profile-box">
            <div className="profile-box-outline" style={{ background: 'transparent', outline: '2px solid #ff043a', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px' }}>
                <Link to="/my-profile">
                    <img src={ProfileIcon} alt="Profile Icon" style={{ height: '25px', verticalAlign: 'middle' }} />
                </Link>
            </div>
            <button className="profile-box-button" style={{ background: 'transparent', border: 'none', color: '#ff043a', fontWeight: 'bold', cursor: 'pointer', outline: '2px solid #ff043a', marginLeft: '15px', width: '100px' }}>
                <Link to="/account" style={{ color: '#ff043a' }}>My Portal</Link>
            </button>
        </div>
    );
};

export default ProfileBox;

