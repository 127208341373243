import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom'; 


function Footer() {
  return (
    <footer className="footer">
      <div className="footer-grid">
        <div className="footer-section">
          <h4>Useful links</h4>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/terms-of-service">Terms of Service</Link></li>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Contact</h4>
          <ul>
            <li><a href="mailto:support@sweatdns.com">support@sweatdns.com</a></li>
            <li><a href="https://discord.gg/sweatvpn">Discord</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        © 2024 Sweat VPN
      </div>
    </footer>
  );
}

export default Footer;
