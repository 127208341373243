import React, { createContext, useContext, useEffect, useState } from 'react';
import { isTokenExpired } from '../authHelper'; 

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && !isTokenExpired(token)) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      localStorage.removeItem('token'); 
    }

    const checkTokenExpirationInterval = setInterval(() => {
      const token = localStorage.getItem('token');
      if (!token || isTokenExpired(token)) {
        setIsLoggedIn(false);
        localStorage.removeItem('token');
        clearInterval(checkTokenExpirationInterval); 
      }
    }, 60000); 

    return () => clearInterval(checkTokenExpirationInterval); 
  }, []);

  const login = (token) => {
    if (!isTokenExpired(token)) {
      localStorage.setItem('token', token);
      setIsLoggedIn(true);
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
