import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Confirmation from "../assets/img/confirmation.gif";

const Success = () => {
    const location = useLocation();
    const history = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const userId = queryParams.get('userId');
        const invoiceId = queryParams.get('invoiceId');
        const couponCode = queryParams.get('couponCode');

        const updatePaymentStatusAndUserData = async () => {
            try {
                const response = await axios.post(`https://api.sweatdns.com/user/invoice-update`, {
                    userId: userId,
                    invoiceNumber: invoiceId,
                    couponCode: couponCode,
                    status: 'success',
                }, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
            
                if (response.status === 409) {
                    console.log('Payment already claimed');
                } else {
                    console.log('Payment status updated successfully', response.data);
                }
            } catch (error) {
                if (error.response && error.response.status === 409) {
                    console.log('Payment already claimed');
                } else {
                    console.error('Failed to update payment status:', error.response || error);
                }
            }
        };
        if (userId && invoiceId) {
            updatePaymentStatusAndUserData();
        }
    }, [location, history]);

    const buttonStyle = {
        transition: 'background-color 0.3s ease', padding: '10px', backgroundColor: '#ff043a', color: 'white', border: 'none', borderRadius: '3px', cursor: 'pointer', fontSize: '16px', marginTop: '10px', maxWidth: '200px', margin: '0 auto'
    };

    return (
        <div className="sucess-container" style={{ background: 'rgba(0, 0, 0, 0.6)', paddingTop: '100px', height: '100vh' }}>
            <h1>Payment successful!</h1>
            <img src={Confirmation} alt="verified account" style={{ width: '50px' }} />
            <p>Thank you for your purchase.</p>
            <button className="payment-button" style={buttonStyle}>
                <Link to="/my-profile" style={{ textDecoration: 'none', color: "#fff" }}>Go to dashboard</Link>
            </button>
        </div>
    );
};

export default Success;
