import React, { useState } from 'react';
import '../App.css';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom'; 
import ErrorMessage from '../components/ErrorMessage';
import SuccessMessage from '../components/SuccessMessage';
import ErrorIcon from '../assets/img/warning.webp'; 
import { useEffect } from 'react';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');  
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  
  const navigate = useNavigate();
  const location = useLocation()


  const getQueryParamValue = (key) => {
    return new URLSearchParams(location.search).get(key);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value.toLowerCase());
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  }

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  useEffect(() => {
    const saveReferralCode = () => {
      const queryParams = new URLSearchParams(location.search);
      const ref = queryParams.get('ref');
      if (ref) {
        localStorage.setItem('referralCode', ref);
      }
    };

    saveReferralCode();
  }, [location]);
  
  const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    if (password.length < 8) {
      setErrorMessage('Password must be at least 8 characters long.');
      return;
    }

   /* if (!strongPasswordRegex.test(password)) {
      setErrorMessage('Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.');
      return;
    }*/

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    const referralCode = getQueryParamValue('ref');

    try {
      const response = await axios.post('https://api.sweatdns.com/user/signup', { email, password, firstName, lastName, ref: referralCode });
      if (response.data.uniqueCode) {
        setSuccessMessage('Account created successfully. Redirecting to OTP verification...');
        setTimeout(() => {
          navigate(`/otp?code=${response.data.uniqueCode}`);
        }, 3000);
      }else {
        setErrorMessage('Signup successful, but verification code was not provided. Please contact support.');
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          setErrorMessage('You already have an account. Please login instead.');
        } else {
          setErrorMessage(error.response.data.message || 'An error occurred. Please try again.');
        }
      } else if (error.request) {
        setErrorMessage('No response received from server.');
      } else {
        setErrorMessage('Error setting up request.');
      }
    }
  };

  return (
    <div className="signup-box-container">
      <div className="signup-form-container">
        <h2 className="signup-header">CREATE YOUR <span className="highlight">SWEATDNS</span> ACCOUNT</h2>
        <p>Create your account and start dominating!</p>
        {errorMessage && <ErrorMessage message={errorMessage} bgColor='#ff043a' color= '#fff' image={ErrorIcon} />}
        {successMessage && <SuccessMessage message={successMessage} />}
       <form onSubmit={handleSubmit} className="signup-form">
          <label>First Name:</label>
          <input type="text" value={firstName} onChange={handleFirstNameChange} placeholder="Enter your first name" required style={{ marginBottom: '20px', padding: '10px', backgroundColor: '#222', border: 'none', color: '#fff', borderRadius: '5px' }} />
          <label>Last Name:</label>
          <input type="text" value={lastName} onChange={handleLastNameChange} placeholder="Enter your first name" required style={{ marginBottom: '20px', padding: '10px', backgroundColor: '#222', border: 'none', color: '#fff', borderRadius: '5px' }} />
          <label>Email</label>
          <input type="email" value={email} onChange={handleEmailChange} placeholder="Enter your email" required style={{ marginBottom: '20px', padding: '10px', backgroundColor: '#222', border: 'none', color: '#fff', borderRadius: '5px' }} />
          <label>Choose password</label>
          <input type="password" value={password} onChange={handlePasswordChange} placeholder="Enter your password" required style={{ marginBottom: '20px', padding: '10px', backgroundColor: '#222', border: 'none', color: '#fff', borderRadius: '5px' }} />
          <label>Confirm your password</label>
          <input type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} placeholder="Confirm your password" required style={{ marginBottom: '20px', padding: '10px', backgroundColor: '#222', border: 'none', color: '#fff', borderRadius: '5px' }} />
          <button type="submit" className="signup-button">SIGN UP</button>
          <p className="terms-text">By signing up you are agreeing to our <a href="/terms-of-service"><span className="login-link" >Terms of Service</span></a></p>
          <p className="already-account" style={{ fontSize: '0.8em' }}>If you already have an account, <a href="/login"><span className="login-link" >Log in</span></a></p>
        </form>
      </div>
      <div className="signup-info-container">
        <ul className="signup-info-list">
          <li>✔ Try 3-days before purchasing</li>
          <li>✔ No credit card or payments required</li>
          <li>✔ Easy and simple setup</li>
          <li>✔ Works on all platforms</li>
          <li>✔ Dominate Warzone 3!</li>
        </ul>
      </div>
    </div>
  );
};

export default Signup;
