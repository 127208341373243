import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';

function FaqSection() {
    return (
        <div className="FAQ-container-account" >
            <div className="faq-section-header" style={{textAlign: 'left', maxWidth: '800px', margin: '0 auto', marginTop: '30px', paddingRight: '10px'}}>
                <h1 className="faq-account-title" style={{color: 'white'}}>Frequently Asked Questions</h1>
                <p>All your troubleshooting questions listed below.</p>
            <div className="FAQ-questions-account">
                <p><span className="faq-titles"><strong><Link style={{ textDecoration: 'none', color: 'rgb(255, 4, 58)' }} to="/faq/what_is_the_best_location">What is the best location to select? ➠</Link></strong></span></p>
                <p><span className="faq-titles"><strong><Link style={{ textDecoration: 'none', color: 'rgb(255, 4, 58)' }} to="/faq/i_changed_my_location_but_it_doesnt_change_in_game">I changed my location, but it doesn't change in game! ➠</Link></strong></span></p>
                <p><span className="faq-titles"><strong><Link style={{ textDecoration: 'none', color: 'rgb(255, 4, 58)' }} to="/faq/why_can_i_reset_only_once_every_24_hours_my_network">Why can I reset only once every 24 hours my network? ➠</Link></strong></span></p>
                <p><span className="faq-titles"><strong><Link style={{ textDecoration: 'none', color: 'rgb(255, 4, 58)' }} to="/faq/will_this_affect_my_ping">Will this affect my ping? ➠</Link></strong></span></p>
                <p><span className="faq-titles"><strong><Link style={{ textDecoration: 'none', color: 'rgb(255, 4, 58)' }} to="/faq/does_this_work_on_my_console">Does this work on my console? ➠</Link></strong></span></p>
                <p><span className="faq-titles"><strong><Link style={{ textDecoration: 'none', color: 'rgb(255, 4, 58)' }} to="/faq/do_i_need_any_hardware_to_connect_it_to_my_console">Do I need any hardware to connect it to my console? ➠</Link></strong></span></p>
                <p><span className="faq-titles"><strong><Link style={{ textDecoration: 'none', color: 'rgb(255, 4, 58)' }} to="/faq/what_are_the_benefits_of_using_this_vpn">What are the benefits of using this VPN? ➠</Link></strong></span></p>
            </div>
            </div>
            
        </div>
    );
}


export default FaqSection;