import React from 'react';
import '../App.css'; 

const Review = ({ review }) => {
  return (
    <div className="review">
      <img className="review-img" src={review.img} alt={review.name} />
      <p className="review-text">{review.text}</p>
      <div className="reviewer-info">
        <p className="reviewer-name">{review.name}</p>       
      </div>
      <p className="reviewer-role">{review.role}</p>
    </div>
  );
};

export default Review;