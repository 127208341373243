import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Invoices = () => {
    const [invoices, setInvoices] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchInvoices = async () => {
            const userId = localStorage.getItem('userId');

            try {
                const response = await axios.get(`https://api.sweatdns.com/user/invoices/${userId}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                setInvoices(response.data);
            } catch (error) {
                console.error('Failed to fetch invoices:', error);
            }
        };

        fetchInvoices();
    }, []);

    const handlePayNowClick = (userId, invoiceId) => {
        navigate(`/checkout?userId=${userId}&invoiceNumber=${invoiceId}`);
    };

    const handleCheckPaymentClick = async (userId, invoiceId) => {
        try {
            const response = await axios.get(`https://api.sweatdns.com/user/invoice-status/${userId}/${invoiceId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });

            if (response.data.paymentStatus === 'Paid') {
                navigate(`/success?userId=${userId}&invoiceId=${invoiceId}`);
            } else {
                alert('Payment is not completed yet. Please try again later.');
            }
        } catch (error) {
            console.error('Failed to check payment status:', error);
            alert('An error occurred while checking payment status.');
        }
    };

    return (
        <div className="invoice-section" style={{ background: 'rgba(0, 0, 0, 0.6)', padding: '20px' }}>
            <h2 className="invoice-header" style={{ textAlign: 'center', color: '#fff', marginTop: '100px' }}>Your Invoices</h2>
            <div className="invoices-container" style={{ background: '#222' }}>
                <div className="invoice-grid-header">
                    <span>Invoice Number</span>
                    <span>Package</span>
                    <span>Amount</span>
                    <span>Coupon</span>
                    <span>Status</span>
                    <span>Date</span>
                    <span>Actions</span>
                </div>
                {invoices.map((invoice, index) => (
                    <div
                        key={invoice._id}
                        className="invoice-grid-row"
                        style={{
                            background: index % 2 === 0 ? '#2a2a2a' : '#333'
                        }}
                    >
                        <span>{invoice.invoiceNumber}</span>
                        <span>{invoice.packageType}</span>
                        <span>
                            {invoice.adjustedAmount !== undefined ? `$${invoice.adjustedAmount.toFixed(2)}` : `$${invoice.amount.toFixed(2)}`}
                        </span>
                        <span>{invoice.couponCode ? invoice.couponCode : 'N/A'}</span>
                        <span>{invoice.paymentStatus}</span>
                        <span>{new Date(invoice.invoiceDate).toLocaleDateString()}</span>
                        <div className="pay-now-container">
                            {invoice.paymentStatus !== 'Paid' && (
                                <>
                                    <button
                                        className="pay-invoice-button"
                                        onClick={() => handlePayNowClick(invoice.userId, invoice.invoiceNumber)}
                                    >
                                        Pay Now
                                    </button>
                                    <button
                                        className="check-payment-button"
                                        onClick={() => handleCheckPaymentClick(invoice.userId, invoice.invoiceNumber)}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        Check Payment
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Invoices;
