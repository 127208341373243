import React from 'react';
import '../App.css';

function Help() {
  return (
    <div className="help">
      <h1>Need Help?</h1>
      <p>Here's how to get in touch with us.</p>
    </div>
  );
}

export default Help;
