import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PaidOrders = () => {
    const [paidInvoices, setPaidInvoices] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchPaidInvoices = async () => {
            try {
                const response = await axios.get('https://api.sweatdns.com/admin/paid-invoices', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                console.log(response.data); // For debugging
                setPaidInvoices(response.data);
            } catch (error) {
                console.error('Error fetching paid invoices:', error);
                setError('Failed to fetch paid invoices');
            }
        };
        fetchPaidInvoices();
    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    const calculateAdjustedAmount = (amount, discountPercentage) => {
        if (discountPercentage) {
            return amount * ((100 - discountPercentage) / 100);
        }
        return amount;
    };

    
    return (
        <div className="coupon-manager-container" style={{ marginTop: '50px' }} >
            <h1>Orders</h1>
            <div className="coupon-manager-header" />
            <div className="admin-grid" style={{ overflowY: 'scroll', maxHeight: '400px' }}>
                <table className="admin-table">
                    <thead>
                        <tr>
                            <th>Invoice Number</th>
                            <th>Package</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paidInvoices.map((invoice, index) => {
                            const finalAmount = invoice.adjustedAmount !== undefined ? invoice.adjustedAmount : calculateAdjustedAmount(invoice.amount, invoice.discountPercentage);
                            return (
                                <tr key={invoice._id} style={{ backgroundColor: index % 2 === 0 ? '#2a2a2a' : '#333333' }}>
                                    <td>{invoice.invoiceNumber}</td>
                                    <td>{invoice.packageType}</td>
                                    <td>${finalAmount.toFixed(2)}</td>
                                    <td>{new Date(invoice.invoiceDate).toLocaleDateString()}</td>
                                    <td>{invoice.paymentStatus}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PaidOrders;
