import React, { useState } from 'react';
import '../App.css';
import axios from 'axios';

const Reset = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(''); 

  const handleEmailChange = (event) => {
    setEmail(event.target.value.toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.sweatdns.com/user/password-reset-request', { email });
      setMessage('If an account with that email exists, we have sent a password reset link.');
      setEmail('');
    } catch (error) {
      console.error('Password reset request failed:', error);
      setMessage('Failed to send password reset link. Please try again later.');
    }
  };

  return (
    <div className="password-reset-container">
      <div className="login-form-container">
        <h2 className="signup-header">Reset <span className="highlight">Password</span></h2>
        <p>Lost your password? We got you, please enter the form below.</p>
        {message && <p className="reset-message" style={{ color: '#ff043a', textAlign: 'center', marginTop: '20px' }}>{message}</p>} 
        <form onSubmit={handleSubmit} className="signup-form">
          <label>Email</label>
          <input type="email" value={email} onChange={handleEmailChange} placeholder="Enter your email" required style={{ marginBottom: '20px', padding: '10px', backgroundColor: '#222', border: 'none', color: '#fff', borderRadius: '5px'}}/>
          <button type="submit" className="signup-button" style={{justifyContent: 'center', maxWidth: '160px'}}>SEND PASSWORD</button>
          <p className="terms-text">Back to <a href="/login"><span className="login-link">login</span></a></p>
        </form>
      </div>
    </div>
  );
};

export default Reset;
