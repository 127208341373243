import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import logo from '../assets/img/logo.webp';
import ProfileBox from './ProfileBox';
import Logout from './Logout';
import { useAuth } from '../context/AuthContext';
import Hamburger from './Hamburger';
require('dotenv').config();

export default function Header() {
  const [isShrunk, setIsShrunk] = useState(false);
  const { isLoggedIn } = useAuth(); 

  useEffect(() => {
    const handleScroll = () => {
      setIsShrunk(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`header ${isShrunk ? 'shrink' : ''}`}>
      <div className="nav-logo">
        <Link to="/" className="nav-home-link">
          <img src={logo} alt="Logo" className="header-logo" />
          <span className="logo-text" style={{ fontWeight: 'bold' }}>SWEATDNS</span>
        </Link>
        {!isLoggedIn && <Hamburger />} 
      </div>

      {!isLoggedIn ? (
        <div className="nav-buttons">
           <Link to="/">Home</Link>
          <div className="nav-buttons" style={{ fontWeight: 'bold' }}>
            <button onClick={() => { window.location.href = `/sign-up`; }}>Try Now</button>
            <button onClick={() => { window.location.href = '/login'; }}>Login</button>
          </div>
        </div>
      ) : (
        <div className="auth-actions">
          <div className="profile-box">
            <ProfileBox />
          </div>
          <div className="logout-box">
            <Logout />
          </div>
        </div>
      )}
    </nav>
  );
}
