import React from "react";
import LogoutIcon from '../assets/img/logout.webp';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; 

const Logout = () => {
    const navigate = useNavigate();
    const { logout } = useAuth(); 
    
    const handleLogout = async () => {
        try {
            localStorage.removeItem('token');
            logout();
            navigate('/');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <div className="logout-box" onClick={handleLogout} style={{ cursor: 'pointer' }}>
            <div className="logout-box-outline" style={{ background: 'transparent', outline: '2px solid #ff043a', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', marginLeft: '-25px', marginRight: '20px' }}>
                <img src={LogoutIcon} alt="Logout Icon" style={{ height: '25px', verticalAlign: 'middle' }} />
            </div>
        </div>
    );
};

export default Logout;
