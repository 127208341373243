import React from 'react';

const ErrorMessage = ({ message, color, bgColor, image}) => {
  return (
    <div style={{
      background: bgColor, //rgb(255 104 104)
      color: color, //#111
      padding: '10px',
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center',
      gap: '10px', 
      margin: '10px 0',
    }}>
      <img src={image} alt="Error" style={{ width: '24px', height: '24px' }} />
      <span>{message}</span>
    </div>
  );
};

export default ErrorMessage;
