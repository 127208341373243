import React from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';

function Question1() {
    return (
        <div className="FAQ-container" >
          <h1 className="FAQ-title" style={{marginTop: '100px'}}>Frequently Asked Questions</h1>
          <p><span className="faq-titles" ><strong><Link style={{textDecoration: 'none',  color: 'rgb(255, 4, 58)'}} to="/faq">⬅ Go back to the FAQ page.</Link></strong></span></p>
          <div className="FAQ-questions" style={{maxWidth: '700px'}}>  
            <h2>What is the best location to select?</h2>
            <p>
             Pick a VPN country whose current timezone is either early morning, afternoon, or after midnight.
             Quads are most likely to encounter easier lobbies.
             Try to join with a random, or a low skilled player as an additional player. This would most likely give much easier lobbies.
            </p>
          </div>
        </div>
      );
    }

export default Question1;