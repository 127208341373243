import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import ErrorMessage from '../components/ErrorMessage';
import InfoIcon from '../assets/img/info.png';
import LoadingGif from '../assets/img/loading.gif';

const MyProfile = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [selectedPackage, setSelectedPackage] = useState('option0');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [globalMessage, setGlobalMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [adminPermissions, setAdminPermissions] = useState(false);

    const messageStyle = {
        textAlign: 'center',
        color: '#ff043a',
    };

    const [userDetails, setUserDetails] = useState({
        name: '',
        lastName: '',
        email: '',
        subscription: '',
        lastPaymentDate: '',
        payment: '',
        endsAt: '',
    });



    const packageDetails = {
        option0: { name: "Trial", amount: 0 },
        option1: { name: "1 Month", amount: 9.99 },
        option2: { name: "1 Year", amount: 49.99 },
    };

    const getSubscriptionStatus = (code) => {
        switch (code) {
            case '0': return 'No Subscription';
            case '1': return 'Trial';
            case '2': return 'Month';
            case '3': return 'Year';
            default: return 'N/A';
        }
    };


    const handleLogout = async () => {
        try {
            logout();
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };


    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                };
                const { data } = await axios.get('https://api.sweatdns.com/user/profile', config);
                setUserDetails({
                    name: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    subscription: data.subscription,
                    lastPaymentDate: data.lastPaymentDate,
                    payment: data.payment,
                    endsAt: data.endsAt,
                    admin: data.adminLevel,
                });

                setSelectedPackage(mapSubscriptionToOption(data.subscription));

                if (data.adminLevel === 1) {
                    setAdminPermissions(true);
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchUserDetails();
    }, []);

    const mapSubscriptionToOption = (subscriptionCode) => {
        switch (subscriptionCode) {
            case '1': return 'option1'; // Trial
            case '2': return 'option2'; // 1 Month
            case '3': return 'option3'; // 1 Year
            default: return 'option0'; // No Subscription
        }
    };

    const now = new Date();
    let expirationText = 'N/A'; // Default text
    if (userDetails.endsAt) {
        const expirationDate = new Date(userDetails.endsAt);
        if (now > expirationDate) {
            expirationText = 'Expired';
        } else {
            expirationText = expirationDate.toLocaleDateString();
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserDetails(prevDetails => ({
            ...prevDetails,
            [name]: value,
        }));
        setSelectedPackage(e.target.value);
    };

    const handlePasswordChange = async () => {
        if (password !== confirmPassword) {
            setMessage('Passwords do not match.');
            return;
        }
        try {
            await axios.put(
                'https://api.sweatdns.com/user/change-password',
                { newPassword: password },
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
            );
            setMessage('Password updated successfully.');
            setPassword('');
            setConfirmPassword('');
            handleLogout();
        } catch (error) {
            console.error('Error updating password:', error);
            setMessage('Failed to update password.');
        }
    };


    const handleActivateTrial = async () => {
        try {
            const response = await axios.put('https://api.sweatdns.com/user/activate-trial', {}, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            console.log(response.data.message);
            setGlobalMessage('Trial activated successfully! Enjoy your subscription.');
        } catch (error) {
            console.error('Error activating trial:', error.response.data.message);
            setGlobalMessage(error.response.data.message || 'Failed to activate trial. Please try again.');
        }
    };

    const handlePackageChange = (e) => {
        setSelectedPackage(e.target.value);
        setGlobalMessage('');
    };

    const handlePurchaseOrTrial = async () => {
        const packageOption = packageDetails[selectedPackage];
        if (selectedPackage === 'option0') {
            handleActivateTrial();
        } else {
            try {
                const response = await axios.post('https://api.sweatdns.com/user/invoices/create', {
                    packageType: packageOption.name,
                    amount: packageOption.amount,
                }, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });

                const userId = localStorage.getItem('userId');
                const invoiceNumber = response.data.invoiceNumber;
                setGlobalMessage('Invoice created successfully');
                setTimeout(() => {
                    navigate(`/checkout?userId=${userId}&invoiceNumber=${invoiceNumber}`);
                }, 2000);
            } catch (error) {
                setGlobalMessage('Failed to create invoice:', error.response?.data?.message || 'An error occurred');
            }
        }
    };


    if (isLoading) {
        return <img src={LoadingGif} alt="Loading..." className="loading-gif" />;
    }


    return (
        <div className="my-profile-section" style={{ background: 'rgba(0, 0, 0, 0.6)', paddingTop: '50px' }}>
            <div className="global-message" style={{ maxWidth: '600px', margin: '0 auto' }}>
                {globalMessage && (
                    <ErrorMessage message={globalMessage} color='#fff' bgColor='rgb(4 93 255)' image={InfoIcon} />
                )}
            </div>


            <div className="account-plan-section" style={sectionStyle}>
                Purchase a Plan
            </div>
            <div className="accounts-details-info-section" style={infoSectionStyle}>
                <div className="account-details-text">
                    <label htmlFor="payment-method">Package</label>
                    <select
                        id="package"
                        name="package"
                        onChange={handlePackageChange}
                        style={inputStyle}
                        value={selectedPackage}
                    >
                        <option value="option0">Trial</option>
                        <option value="option1">1 Month</option>
                        <option value="option2">1 Year</option>
                    </select>
                    <div className="purchase-button">
                        <button onClick={handlePurchaseOrTrial}
                            onMouseEnter={e => e.target.style.backgroundColor = '#e60000'}
                            onMouseLeave={e => e.target.style.backgroundColor = '#ff043a'}
                            style={{ width: '100px', padding: '10px', backgroundColor: '#ff043a', color: 'white', border: 'none', borderRadius: '3px', cursor: 'pointer', fontSize: '16px', marginTop: '10px', maxWidth: '200px', margin: '0 auto' }}
                        >
                            {selectedPackage === 'option0' ? 'Free' : 'Purchase'}
                        </button>
                    </div>
                    <p className="invoices-text">
                        <a style={{ textDecoration: 'underline', color: '#ff043a', cursor: 'pointer' }} href="/invoices">Check invoices</a>
                    </p>
                </div>
            </div>


            <div className="account-details-section" style={sectionStyle}>
                Account Details
            </div>
            <div className="accounts-details-info-section" style={infoSectionStyle}>
                <div className="account-details-text">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={userDetails.name}
                        onChange={handleInputChange}
                        style={inputStyle}
                    />
                    <label htmlFor="lastName">Last Name</label>
                    <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={userDetails.lastName}
                        onChange={handleInputChange}
                        style={inputStyle}
                    />
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={userDetails.email}
                        onChange={handleInputChange}
                        style={inputStyle}
                    />
                    <label htmlFor="subscription">Subscription</label>
                    <input
                        type="text"
                        id="subscription"
                        name="subscription"
                        disabled={true}
                        value={getSubscriptionStatus(userDetails.subscription)}
                        onChange={handleInputChange}
                        style={inputStyle}
                    />
                    <label htmlFor="expiration">Expiration</label>
                    <input
                        type="text"
                        id="expiration"
                        name="expiration"
                        disabled={true}
                        value={expirationText}
                        onChange={handleInputChange}
                        style={inputStyle}
                    />
                </div>
                {adminPermissions && (
                    <div className="admin-permissions-section" style={sectionStyle}>
                        <button onClick={() => navigate('/admin')}
                            style={{ transition: 'background-color 0.3s ease', display: 'flex', padding: '10px', backgroundColor: '#ff043a', color: 'white', border: 'none', borderRadius: '3px', cursor: 'pointer', fontSize: '16px', marginTop: '10px', maxWidth: '200px', margin: '0 auto' }}>
                            Admin Portal
                        </button>
                    </div>
                )}
            </div>



            <div className="password-change-section" style={sectionStyle}>
                Change Password
            </div>
            <div className="password-change-info-section" style={infoSectionStyle}>
                <div className="password-change-text">
                    <label htmlFor="password">New Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={inputStyle}
                    />
                    <label htmlFor="confirmPassword">Confirm New Password</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        style={inputStyle}
                    />
                </div>
                <button className="change-password-button"
                    onMouseEnter={e => e.target.style.backgroundColor = '#e60000'}
                    onMouseLeave={e => e.target.style.backgroundColor = '#ff043a'}
                    style={buttonStyle}
                    onClick={handlePasswordChange}
                >
                    Change Password
                </button>
                {message && <p style={messageStyle}>{message}</p>}
            </div>
        </div>
    );
};

// Styles
const sectionStyle = {
    background: '#222', color: 'rgb(151 149 149)', fontWeight: 'bold', maxWidth: '600px', margin: '0 auto', padding: '10px', borderRadius: '5px', marginTop: '20px'
};
const infoSectionStyle = {
    background: '#333', maxWidth: '600px', margin: '0 auto', padding: '10px', marginBottom: '20px', textAlign: 'left'
};
const inputStyle = {
    marginBottom: '20px', padding: '10px', width: '300px', backgroundColor: 'rgba(25,25,25)', border: 'none', color: '#fff', borderRadius: '5px', display: 'flex', margin: '10px auto'
};
const buttonStyle = {
    transition: 'background-color 0.3s ease', padding: '10px', backgroundColor: '#ff043a', color: 'white', border: 'none', borderRadius: '3px', cursor: 'pointer', fontSize: '16px', marginTop: '10px', maxWidth: '200px', margin: '0 auto'
};

export default MyProfile;
