import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import '../App.css';
import loadingGif from '../assets/img/loading.gif';
import Visa from '../assets/img/payment/visa_website.webp';
import Mastercard from '../assets/img/payment/creditcard_website.webp';
import Ideal from '../assets/img/payment/ideal_website.webp';
import Bancontact from '../assets/img/payment/bancontact_website.webp';
import Sofort from '../assets/img/payment/sofort_website.webp';
import Blik from '../assets/img/payment/blik_website.webp';
import { useNavigate } from 'react-router-dom';

const Checkout = () => {
  const [invoice, setInvoice] = useState({ invoiceNumber: "", amount: 0 });
  const [paymentStatus, setPaymentStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [couponMessage, setCouponMessage] = useState('');
  const [discount, setDiscount] = useState(0);
  const [couponCode, setCouponCode] = useState('');
  const [couponApplied, setCouponApplied] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [paymentStatusMessage] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('credit_card');
  const navigate = useNavigate();


  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const userId = queryParams.get('userId');
    const invoiceNumber = queryParams.get('invoiceNumber');
    const paymentStatus = queryParams.get('paymentStatus');
    const invoiceId = queryParams.get('invoiceId');
    const savedCouponCode = localStorage.getItem('referralCode');
    
    if (savedCouponCode) {
      setCouponCode(savedCouponCode);
    }

    const updatePaymentStatusAndUserData = async (invoiceId) => {
      const response = await axios.get(`http:/localhost:8080/user/invoice/${invoiceId}`, {
        params: { status: 'success' }
      });
      return response.data;
    };

    if (paymentStatus === 'success') {
      updatePaymentStatusAndUserData(invoiceId)
        .then(() => {
          console.log('Payment status updated successfully');
          setTimeout(() => window.location.href = '/account', 5000);
        })
        .catch(error => console.error('Failed to update payment status:', error));
    } else if (paymentStatus === 'cancel') {
      console.log('Payment was cancelled');
    }

    const fetchInvoiceDetails = async () => {
      try {
        const response = await axios.get(`https://api.sweatdns.com/user/checkout/${userId}/${invoiceNumber}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setInvoice(response.data);
        if (response.data.paymentStatus === 'Paid') {

          setPaymentStatus('Paid');
          console.log('Invoice is already paid');
          navigate('/account');
        }
      } catch (error) {
        console.error('Failed to fetch invoice:', error);
      }
    };


    const fetchUserDetails = async () => {
      try {
        const response = await axios.get('https://api.sweatdns.com/user/profile', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setUserDetails(response.data);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    fetchInvoiceDetails();
    fetchUserDetails();
  }, [location]);


  useEffect(() => {
    if (couponCode && !couponApplied) {
      handleCouponValidation();
    }
  }, [couponCode, couponApplied]);



  const handleCreateStripeCustomer = async (userDetails) => {
    try {
      const response = await axios.post('https://api.sweatdns.com/user/create-customer', {
        userId: userDetails._id,
        email: userDetails.email,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      console.log('Stripe customer created:', response.data.customerId);
      return response;
    } catch (error) {
      console.error('Failed to create Stripe customer:', error.response.data.message);
      throw error;
    }
  };

  const handleStripePayment = async () => {
    setIsLoading(true);

    if (!userDetails) {
      console.error("User details not loaded");
      setIsLoading(false);
      return;
    }

    let stripeCustomerId = userDetails.stripeCustomerId;

    if (!stripeCustomerId) {
      try {
        const customerResponse = await handleCreateStripeCustomer(userDetails);
        stripeCustomerId = customerResponse.data.customerId;

        setUserDetails({ ...userDetails, stripeCustomerId: stripeCustomerId });
      } catch (error) {
        console.error('Failed to create Stripe customer:', error);
        setIsLoading(false);
        return;
      }
    }

    try {
      const { data } = await axios.post('https://api.sweatdns.com/user/create-checkout-session', {
        customerId: stripeCustomerId,
        amount: Math.round(invoice.amount * 100),
        invoiceId: invoice.invoiceNumber,
        userId: invoice.userId,
        couponCode: couponCode,
        paymentMethod: selectedPaymentMethod,
        orderId: invoice.invoiceNumber,
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      window.location.href = data.url;
    } catch (error) {
      console.error('Error initiating payment:', error.response?.data?.message || error.message);
      setPaymentStatus('Failed');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCouponValidation = async () => {
    if (!couponCode) {
      setCouponMessage('Please enter a coupon code.');
      return;
    }
    try {
      const response = await axios.post(
        'https://api.sweatdns.com/user/validate-coupon',
        { code: couponCode },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (response.data.discountPercentage) {
        setDiscount(response.data.discountPercentage);
        setInvoice({
          ...invoice,
          amount: invoice.amount * (1 - response.data.discountPercentage / 100),
        });
        setCouponMessage(`Coupon applied! ${response.data.discountPercentage}% discount.`);
        setCouponApplied(true);
      } else {
        setCouponMessage('Invalid coupon code.');
      }
    } catch (error) {
      console.error('Error validating coupon:', error);
      setCouponMessage(error.response.data.message || 'Failed to validate coupon.');
    }
  };


  return (
    <div className="checkout-section" style={{ background: 'rgba(0, 0, 0, 0.6)', paddingTop: '100px' }}>
      <div className="payment-status-message">
        {paymentStatusMessage}
      </div>
      <div className="account-details-section" style={{ background: '#222', color: 'rgb(151 149 149)', fontWeight: 'bold', maxWidth: '600px', margin: '0 auto', padding: '10px', borderRadius: '5px', marginTop: '20px' }}>
        Checkout
      </div>
      <div className="accounts-details-info-section" style={{  background: '#333', maxWidth: '600px', margin: '0 auto', padding: '10px', textAlign: 'left', marginBottom: '100px'}}>
        <div className="account-details-text">
          <div className="invoice-details" style={{ color: '#222' }}>
            <h3 style={{ color: '#fff' }}>Invoice Details</h3>
            <p style={{ color: '#fff' }}><strong>Invoice Number:</strong> #{invoice.invoiceNumber}</p>
            <p style={{ color: '#fff' }}><strong>Amount:</strong> ${invoice.amount.toFixed(2)}</p>
            <label htmlFor="couponCode" style={{ color: '#fff' }}><strong>Coupon code:</strong></label>
            <div className={`coupon-validation-message ${discount ? 'success' : ''}`}>
              {couponMessage}
            </div>
            <div>
              <input
                type="text"
                id="couponCode"
                value={couponCode}
                onChange={(e) => {
                  setCouponCode(e.target.value);
                  if (couponMessage) {
                    setCouponMessage('');
                  }
                }}
                placeholder="Enter coupon code"
                style={inputStyle}
                disabled={couponApplied}
              />
              <button onClick={handleCouponValidation} style={buttonStyle} disabled={couponApplied}>Check</button>
            </div>
          </div>
          <div className="payment-options">
            <h3 style={{ color: '#fff' }}>Payment Options</h3>
            <img src={Visa} style={{ width: '10%', marginLeft: '5px' }} alt="Visa" />
            <img src={Mastercard} style={{ width: '10%', marginLeft: '5px' }} alt="Mastercard" />
            <img src={Ideal} style={{ width: '10%', marginLeft: '5px' }} alt="Ideal" />
            <img src={Bancontact} style={{ width: '10%', marginLeft: '5px' }} alt="Bancontact" />
            <img src={Sofort} style={{ width: '10%', marginLeft: '5px' }} alt="Sofort" />
            <img src={Blik} style={{ width: '10%', marginLeft: '5px' }} alt="Blik" />
            <div>
              <input type="radio" id="credit_card" name="paymentMethod" value="credit_card" checked={selectedPaymentMethod === 'credit_card'} onChange={(e) => setSelectedPaymentMethod(e.target.value)} />
              <label htmlFor="credit_card" style={{ color: '#fff' }}>Credit Card</label>
            </div>
            <div>
              <input type="radio" id="ideal" name="paymentMethod" value="ideal" checked={selectedPaymentMethod === 'ideal'} onChange={(e) => setSelectedPaymentMethod(e.target.value)} />
              <label htmlFor="ideal" style={{ color: '#fff' }}>iDEAL</label>
            </div>
            <div>
              <input type="radio" id="bancontact" name="paymentMethod" value="bancontact" checked={selectedPaymentMethod === 'bancontact'} onChange={(e) => setSelectedPaymentMethod(e.target.value)} />
              <label htmlFor="bancontact" style={{ color: '#fff' }}>Bancontact</label>
            </div>
            <div>
              <input type="radio" id="sofort" name="paymentMethod" value="sofort" checked={selectedPaymentMethod === 'sofort'} onChange={(e) => setSelectedPaymentMethod(e.target.value)} />
              <label htmlFor="sofort" style={{ color: '#fff' }}>SOFORT</label>
            </div>
            <div>
              <input type="radio" id="blik" name="paymentMethod" value="blik" checked={selectedPaymentMethod === 'blik'} onChange={(e) => setSelectedPaymentMethod(e.target.value)} />
              <label htmlFor="sofort" style={{ color: '#fff' }}>BLIK</label>
            </div>
            <div>
              <button className="payment-button stripe" onClick={() => handleStripePayment()}>
                Pay Now
              </button>
              <div className="divider" style={{ border: '1px solid #fff', margin: '0 auto', maxWidth: '60px', marginBottom: '30px', marginTop: '30px' }}> </div>
            </div>
          </div>
          {isLoading && <img src={loadingGif} style={{ width: '25px', height: '25px', marginTop: '30px' }} alt="Processing payment..." />}
          {paymentStatus && <p>Payment Status: {paymentStatus}</p>}
        </div>

      </div>
    </div>
  )
};

// Styles
const sectionStyle = {
  background: '#222', color: 'rgb(151 149 149)', fontWeight: 'bold', maxWidth: '600px', margin: '0 auto', padding: '10px', borderRadius: '5px', marginTop: '100px'
};
const infoSectionStyle = {
  background: '#333', maxWidth: '600px', margin: '0 auto', padding: '10px', textAlign: 'left', marginBottom: '50px'
};

const inputStyle = {
  marginBottom: '20px', padding: '10px', width: '300px', backgroundColor: 'rgba(25,25,25)', border: 'none', color: '#fff', borderRadius: '5px', display: 'flex', margin: '10px auto'
};

const buttonStyle = {
  transition: 'background-color 0.3s ease', padding: '10px', backgroundColor: '#ff043a', color: 'white', border: 'none', borderRadius: '3px', cursor: 'pointer', fontSize: '16px', marginTop: '10px', maxWidth: '200px', margin: '0 auto',
};

export default Checkout;

