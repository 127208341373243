import React from 'react';
import '../App.css'; 

function privacy() {
  return (
    <div className="privacy-container">
      <div className="privacy-header">
        <h1 className="privacy-title" style={{color: '#ff043a' , marginTop: '100px'}}>Privacy Policy</h1>
      </div>
      <div className="privacy-content">
        <section className="privacy-section" style={{maxWidth: '800px', margin: '0 auto'}}>
          <p>Welcome to www.sweatdns.com, a digital platform dedicated to providing [brief
          description of products/services]. This Privacy Policy outlines how ByteFusion Sp.
          ("us", "we", or "our") collects, uses, and protects your information when you use our
          services through www.sweatdns.com ("Site")</p>
          <h2 className="privacy-subtitle"> 1. Information We Collect</h2>
          <p>At SweatDNS, our commitment to your privacy is paramount. As such, we do not collect personal data from our users. Our services are designed to respect your privacy and to adhere to the highest standards of data protection.</p>
        </section>
        <section className="privacy-section"  style={{maxWidth: '800px', margin: '0 auto'}}>
          <h2 className="privacy-subtitle">2. How We Use Your Information</h2>
          <p>Since we do not collect personal data, the scope of using your information is limited. However, we use your contact information to send necessary communications, such as:</p>
            <li>Password reset emails</li>
            <li>Notifications about your account, including any subscription expirations or renewals</li>
            <li>Updatesorchanges to our services or policies</li>
        </section>
        <section className="privacy-section" style={{maxWidth: '800px', margin: '0 auto'}}>
          <h2 className="privacy-subtitle">3. Payment Information</h2>
          <p>We process one-time payments for our digital products through a secure, third-party payment processor. We do not store or have access to your credit card or payment account information. The processing of payments will be subject to the terms, conditions, and privacy policies of the payment processor, in addition to this Privacy Policy.</p>
        </section>
        <section className="privacy-section" style={{maxWidth: '800px', margin: '0 auto'}}>
          <h2 className="privacy-subtitle">4. Our Own Login System</h2>
          <p>To enhance your experience with our digital products, we have implemented our own login system. This system is designed with your privacy in mind, requiring only the minimal necessary information for account creation and access to our services.</p>
        </section>
        <section className="privacy-section" style={{maxWidth: '800px', margin: '0 auto'}}>
          <h2 className="privacy-subtitle">5. Data Protection</h2>
          <p>The security of your information is important to us. While we do not collect personal data, we protect your account information through industry-standard measures to prevent unauthorized access, disclosure, modification, or destruction of your account details.</p>
        </section>
        <section className="privacy-section" style={{maxWidth: '800px', margin: '0 auto'}}>
          <h2 className="privacy-subtitle">6. Changes to This Privacy Policy</h2>
          <p>We reserve the right to update or change our Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
        </section>
        <section className="privacy-section" style={{maxWidth: '800px', margin: '0 auto'}}>
          <h2 className="privacy-subtitle">7. Contact Us</h2>
          <p> If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@sweatdns.com">support@sweatdns.com</a></p>
        </section>
        <p className="privacy-update-notice" style={{ marginBottom: '100px'}}>We reserve the right to modify these privacy at any time. Please review these privacy regularly to ensure you are aware of any changes.</p>
      </div>
    </div>
  );
}

export default privacy;
