import React, { useState, useRef } from 'react';
import '../App.css'; 
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PricingCard = ({ title,subtitle, price, features, buttonText}) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth(); 

  const onButtonClick = () => {
    if (isLoggedIn) {
      navigate('/account');
    } else {
      navigate('/sign-up');
    }
  };

  return (
    <div className="pricing-card">
      <h2 className="pricing-title">{title}</h2>
      <p className="pricing-subtitle-text">{subtitle}</p>
      <p className="pricing-price">{price}</p>
      <ul className="pricing-features">
        {features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
      <button className="btn" style={{fontWeight: 'bold'}} onClick={onButtonClick}>{buttonText}</button>
    </div>
  );
};

export default PricingCard;
