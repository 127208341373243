import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import Admin from '../components/Admin';

const AdminPage = () => {
    const [isAdmin, setIsAdmin] = useState(null);

    useEffect(() => {
        const checkIfAdmin = async () => {
            try {
                const { data } = await axios.get('https://api.sweatdns.com/user/profile', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                setIsAdmin(data.adminLevel);
            } catch (error) {
                console.error('Error fetching admin status:', error);
                setIsAdmin(false);
            }
        };
        checkIfAdmin();
    }, []);

    if (isAdmin === null) {
        return <div>Loading...</div>;
    }

    if (!isAdmin) {
        return <Navigate to="/" replace />;
    }

    return (
        <div className="admin-page-container" style={{ background: 'rgba(0,0,0,0.7)' }}>
            <Admin />
        </div>
    );
};

export default AdminPage;
