import React from "react";
import PSN1 from '../assets/img/1.png';
import PSN2 from '../assets/img/2.png';
import PSN3 from '../assets/img/3.png';
import PSN4 from '../assets/img/4.png';
import PSN5 from '../assets/img/PSN5.png';

const PsnInstructions = () => {
    return (
      <div className="psn-container" style={{background: '#222', maxWidth: '700px', margin: '0 auto', marginTop: '30px', padding: '20px', textAlign: 'left'}}>
        <h2 className="psn-container-header" style={{margin: '0 auto', marginBottom: '40px'}}>Playstation Setup</h2>
        <p>1. Go to your "Settings" in your Playstation and click "Network".</p>
        <img src={PSN1} alt="PSN1" style={{width: '100%', margin: '20px 0'}} />
        <p>2. Make sure you are connected.</p>
        <p>3. Click on "Advancend Settings" in your Playstation.</p>
        <img src={PSN2} alt="PSN2" style={{width: '100%', margin: '20px 0'}} />
        <p>4. Click and change it to "Manual".</p>
        <img src={PSN3} alt="PSN3" style={{width: '100%', margin: '20px 0'}} />
        <img src={PSN4} alt="PSN4" style={{width: '100%', margin: '20px 0'}} />
        <p>5. Leave everything as default except for "Primary and Secundary DNS".</p>
        <p>6. Enter the "Values" for "Primary and Secundary DNS" address which is shown above.</p>
        <img src={PSN5} alt="PSN5" style={{width: '100%', margin: '20px 0'}} />
        <p>7. Click "OK"and "Test Connection".</p>
        <p>8. Start your game and enjoy!</p>
        <div className="devider" style={{border: '1px solid #333', margin: '20px 0'}}></div>
      </div>
    );
  };

  export default PsnInstructions;