import React from 'react';
import '../App.css';

const StatusCard = ({ title, helpText }) => {

  return (
    <div className="status-card">
      <h4 className="status-header">{title}</h4>
      <div className="status-content">
        {helpText}
      </div>
    </div>
  );
};
export default StatusCard;
