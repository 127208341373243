export const faqData = [
    {
      question: 'What is the best location to select?',
      answer: `Pick a VPN country whose current timezone is either early morning, afternoon, or after midnight.
               Quads are most likely to encounter easier lobbies.
               Try to join with a random, or a low skilled player as an additional player. This would most likely give much easier lobbies.`
    },
    {
      question: 'How do I reset my password?',
      answer: `To reset your password, click on the "Forgot Password" link on the login page and follow the instructions.`
    },
    {
      question: 'What payment methods are accepted?',
      answer: `We accept major credit cards, PayPal, and bank transfers.`
    },
    {
      question: 'How do I contact customer support?',
      answer: `You can contact customer support through the "Contact Us" page on our website or by emailing support@example.com.`
    },
    {
      question: 'What is the return policy?',
      answer: `You can return products within 30 days of purchase for a full refund.`
    },
    {
      question: 'How do I update my account information?',
      answer: `Log in to your account and go to the "Account Settings" page to update your information.`
    },
    {
      question: 'Where can I find the latest updates?',
      answer: `The latest updates are available on our "News" page or through our social media channels.`
    }
  ];