import React from 'react';
import '../App.css';

function privacy() {
  return (
    <div className="privacy-container">
      <div className="privacy-header">
        <h1 className="privacy-title" style={{ color: '#ff043a', marginTop: '100px' }}>Terms of Service</h1>
      </div>
      <div className="privacy-content">
        <section className="privacy-section" style={{ maxWidth: '800px', margin: '0 auto' }}>
          <p> Welcome to www.sweatdns.com. These Terms of Service ("Terms") govern your use of
            www.sweatdns.com and its associated services (the "Service"), operated by ByteFusion Sp.
            ("us", "we", or "our"). By accessing or using the Service, you agree to be bound by these
            Terms. If you disagree with any part of the terms, then you do not have permission to access
            the Service.</p>
        </section>
        <section className="privacy-section" style={{maxWidth: '800px', margin: '0 auto'}}>
          <h2 className="privacy-subtitle">2. User Responsibilities</h2>
          <p>By using the Service, you agree not to:</p>
          <li>Use the Service for any unlawful purpose or for promotion of illegal activities.</li>
          <li>Attempt to bypass or break any security mechanism on the Service or use the Service in any other manner that poses a security or service risk to ByteFusion Sp., to any user of our Service, or to any of our or their respective customers.</li>
          <li>Test the vulnerability of our system or network.</li>
          <li>Use automated systems or software to extract data from the Service ("scraping"), without prior written consent from ByteFusion Sp.</li>
          <li>Introduce any viruses, trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</li>
          <li>Attempt to interfere with, compromise the system integrity or security, or decipher any transmissions to or from the servers running the Service.</li>
          <li>Use bots or other automated methods to use the Service.</li>
        </section>
        <section className="privacy-section" style={{maxWidth: '800px', margin: '0 auto'}}>
          <h2 className="privacy-subtitle">3. Free Trial</h2>
          <p>We offer a free trial to all new users. However, we reserve the right to ban any account if we suspect that it is being used to intentionally disrupt our services, including but not limited to creating fake accounts. Only one account is allowed per network to ensure fair use of our services.</p>
        </section>
        <section className="privacy-section" style={{maxWidth: '800px', margin: '0 auto'}}>
          <h2 className="privacy-subtitle">5. Order Process</h2>
          <p>We accept payments via credit card, iDEAL, Bancontact, SOFORT, and BLIK through our payment processor, Stripe. All refunds are processed through Stripe as well.</p>
        </section>
        <section className="privacy-section" style={{maxWidth: '800px', margin: '0 auto'}}>
          <h2 className="privacy-subtitle">6. Refunds</h2>
          <p>Due to the nature of our digital product system, we do not offer refunds once a subscription is activated.</p>
        </section>
        <section className="privacy-section" style={{maxWidth: '800px', margin: '0 auto'}}>
          <h2 className="privacy-subtitle">7. Privacy</h2>
          <p>Please refer to our Privacy Policy for information on how we collect, use, and share your information.</p>
        </section>
        <section className="privacy-section" style={{maxWidth: '800px', margin: '0 auto'}}>
          <h2 className="privacy-subtitle">8. Alterations</h2>
          <p>We reserve the right to make changes to the Service, Policies, and these Terms at any time without notice.</p>
        </section>
        <section className="privacy-section" style={{maxWidth: '800px', margin: '0 auto'}}>
          <h2 className="privacy-subtitle">9. Disputes</h2>
          <p>If disputes are not brought within 30 days, we reserve the right to seek remedy in court for disputes which may arise.</p>
        </section>
        <section className="privacy-section" style={{maxWidth: '800px', margin: '0 auto'}}>
          <h2 className="privacy-subtitle">10.  Governing Law</h2>
          <p>These Terms shall be governed and construed in accordance with the laws of Lublin, Poland, without regard to its conflict of law provisions.</p>
        </section>
        <section className="privacy-section" style={{maxWidth: '800px', margin: '0 auto'}}>
          <h2 className="privacy-subtitle">11.  Contact</h2>
          <p>If you have any questions about these Terms, please contact us.</p>
        </section>
        <p className="privacy-update-notice" style={{maxWidth: '800px', margin: 'auto', marginBottom: '100px'}}>We reserve the right to modify these privacy at any time. Please review these privacy regularly to ensure you are aware of any changes.</p>
      </div>
    </div>
  );
}

export default privacy;
