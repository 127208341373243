import React from 'react';
import '../App.css';
import NetworkCard from '../components/NetworkCard';
import TabComponent from '../components/TabComponent';
import VpnCard from '../components/VpnCard';
import FaqSection from '../components/FaqSection';

const Account = () => {
  return (
    <div className="account-page-container">
      <div className="account-second-section" style={{ background: 'rgba(0, 0, 0, 0.6)'}}>
      <div className="connection-section" style={{marginBottom: '45px'}}>
        <TabComponent />  
        </div>
        <div className="account-section-body" style={{ maxWidth: '800px', margin: 'auto', marginBottom: '-80px' }}>
          <NetworkCard
            max_Width={'800px'}
            title="① Activate SweatDNS On Your Network"
            isActive={true}
            ipAddress="192.168.1.1"
            helpText="Activate SweatDNS on your network. Please make sure that your gaming device is on the same network as SweatDNS. If you use SweatDNS from another network it won't work. You can only reset your Network once every 48 hours."
          />
        </div>
        <div className="vpn-location-section" style={{ margin: 'auto', marginTop: '100px' }}>
        <VpnCard
        max_Width={'800px'}
        title="② Choose a VPN Location"
        helpText="Choose a VPN location and follow the instructions from below. The server status is an indicator of the server based on its local time."
        />  
        </div>
        <div className="divider" style={{border: '1px solid rgb(51, 51,51)', margin: '0 auto', maxWidth: '60px', marginTop: '60px', marginBottom: '60px'}}> </div>
        <FaqSection />
      </div>
    </div> 
  );
};

export default Account;
