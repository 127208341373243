import React, { useState } from "react";
import HamburgerIcon from "../assets/img/hamburger.png";
import CloseIcon from "../assets/img/delete.png"; 

const Hamburger = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNav = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div style={{ position: 'fixed', top: 0, right: 0, zIndex: 999, padding: '10px' }}>
            <img className="hamburger-icon"
                src={isOpen ? CloseIcon : HamburgerIcon}
                alt={isOpen ? "Close Menu" : "Open Menu"}
                onClick={toggleNav}
                style={{ cursor: 'pointer', width: '40px', height: '40px' }}
            />
            <div id="mySidenav" className="sidenav" style={{
                width: isOpen ? "100%" : "0",
                height: '100%',
                position: 'fixed',
                zIndex: 998,
                top: 0,
                right: 0,
                backgroundColor: '#111',
                overflowX: 'hidden',
                transition: 'width 0.5s ease',
                paddingTop: '60px',
            }}>
                <img
                    src={CloseIcon}
                    alt="Close Menu"
                    onClick={() => setIsOpen(false)} // Close the menu by setting isOpen to false
                    style={{ cursor: 'pointer', width: '30px', height: '30px', zIndex: 1002 }} // Ensure zIndex is high for visibility
                />
                <a href="/" style={linkStyle}>Home</a>
                <a href="/help" style={linkStyle}>Help</a>
                <a href="/sign-up" style={linkStyle}>signup</a>
                <a href="/login" style={linkStyle}>login</a>
            </div>
        </div>
    );
};

const linkStyle = {
    padding: '8px',
    textDecoration: 'none',
    fontSize: '25px',
    color: '#818181',
    display: 'block',
    transition: '0.3s'
};

export default Hamburger;
