import React, { useState } from 'react';
import '../App.css';
import StatusCard from './StatusCard'; 
import PsnInstructions from './PsnInstructions';
import PCInstructions from './PcInstructions';
import XboxInstructions from './XboxInstructions';

const tabs = [
  { id: 'pc', title: 'PC', content: <PCInstructions /> },
  { id: 'psn', title: 'PSN', content: <PsnInstructions /> },
  { id: 'xbox', title: 'XBOX', content: <XboxInstructions /> },
];


const TabComponent = () => {
  const [activeTab, setActiveTab] = useState(null);

  const toggleTab = (tabId) => {
    setActiveTab(activeTab => activeTab === tabId ? null : tabId);
  };

  
  return (
    <div className="tab-component">     
      <h2 style={{textAlign: 'center', color: '#fff', marginTop: '30px'}}>INSTRUCTIONS</h2>
      <div className="tab-titles" style={{background: '#222', maxWidth: '800px', margin: '0 auto'}}>
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`tab-title ${activeTab === tab.id ? 'active' : ''}`}
            onClick={() => toggleTab(tab.id)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="tab-content" style={{ maxWidth: '800px', margin: '0 auto', marginTop: '-30px'}}>      
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab-panel ${activeTab === tab.id ? 'active' : 'hidden'}`}>
            <StatusCard title={tab.title} helpText={tab.content} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabComponent;
