import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import axios from 'axios';

const PasswordReset = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    return regex.test(password);
  };

 useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get('code');

    const verifyToken = async () => {
      try {
        const response = await axios.post('https://api.sweatdns.com/user/verify-reset-token', { token });
        console.log(response.data.message);
      } catch (error) {
        setMessage('Invalid or expired password reset token. Please request a new one.');
        setTimeout(() => navigate('/'), 3000);
      }
    };

    if (token) {
      verifyToken();
    } else {
      navigate('/');
    }
  }, [navigate]);


  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);
    setMessage('');

    if (!validatePassword(password)) {
      setPasswordError('Password must be at least 8 characters long and include uppercase, lowercase, number, and special character.');
    } else {
      setPasswordError('');
    }
  };



  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (!validatePassword(newPassword)) {
      setMessage('Password does not meet the required criteria.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }

    const query = new URLSearchParams(window.location.search);
    const token = query.get('code');

    try {
      await axios.post('https://api.sweatdns.com/user/reset-password', { token, newPassword });
      setMessage('Password successfully reset. Redirecting to login...');
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      setMessage('Failed to reset password. Please try again.');
    }
  };

  return (
    <div className="password-reset-container">
      <div className="login-form-container">
        <h2 className="signup-header">Password <span className="highlight">Reset</span></h2>
        <p>Please enter your new password below.</p>
        {message && <p className="reset-message" style={{ color: '#ff043a', textAlign: 'center', marginTop: '20px' }} >{message}</p>}
        <form onSubmit={handlePasswordReset} className="signup-form">
          <label>Password</label>
          <input
           type="password"
           placeholder="New Password"
           required
           value={newPassword}
           onChange={handlePasswordChange}
            style={{ marginBottom: '20px', padding: '10px', backgroundColor: '#222', border: 'none', color: '#fff', borderRadius: '5px' }}
          />
          <label>confirm Password</label>
          <input
            type="password"
            placeholder="Confirm Password"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            style={{ marginBottom: '20px', padding: '10px', backgroundColor: '#222', border: 'none', color: '#fff', borderRadius: '5px' }}
          />

          <button type="submit" style={{ background: '#ff043a', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer', marginTop: '10px', maxWidth: '200px', margin: '0 auto' }}>Reset Password</button>
          <p className="terms-text">Back to <a href="/login"><span className="login-link">login</span></a></p>
        </form>
      </div>
    </div>
  );
};

export default PasswordReset;
