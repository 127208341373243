import React from "react";
import XBOX1 from '../assets/img/XBOX1.png';
import XBOX2 from '../assets/img/XBOX2.png';
import XBOX3 from '../assets/img/XBOX3.png';
import XBOX4 from '../assets/img/XBOX4.png';
import XBOX5 from '../assets/img/XBOX5.png';
import XBOX6 from '../assets/img/XBOX6.png';
import XBOX7 from '../assets/img/XBOX7.png';

const XboxInstructions = () => {
    return (
      <div className="xbox-container" style={{background: '#222', maxWidth: '700px', margin: '0 auto', marginTop: '30px', padding: '20px', textAlign: 'left'}}>
        <h2 className="xbox-container-header" style={{margin: '0 auto', marginBottom: '40px'}}>Xbox Setup</h2>
        <p>1. Go to "Settings".</p>
        <img src={XBOX1} alt="XBOX1" style={{width: '100%', margin: '20px 0'}} />
        <p>2. Select "Network".</p>
        <img src={XBOX2} alt="XBOX2" style={{width: '100%', margin: '20px 0'}} />
        <p>3. Select "Advanced settings".</p>
        <img src={XBOX3} alt="XBOX3" style={{width: '100%', margin: '20px 0'}} />
        <p>4. Select "DNS settings".</p>
        <img src={XBOX4} alt="XBOX4" style={{width: '100%', margin: '20px 0'}} />
        <p>5. Select "Manual".</p>
        <img src={XBOX5} alt="XBOX5" style={{width: '100%', margin: '20px 0'}} />
        <p>6. Enter the "Values" for "Primary and Secundary DNS" address which is shown above.</p>
        <img src={XBOX6} alt="XBOX6" style={{width: '100%', margin: '20px 0'}} />
        <p>7. Click "OK"and "Test Connection".</p>
        <img src={XBOX7} alt="XBOX7" style={{width: '100%', margin: '20px 0'}} />
        <p>8. Xbox doesn't show the geographical location in game.</p>
        <p>9. To verify if everything is correctly setup, Start browser in your console</p>
        <p>10. Go to www.sweatdns.com/dns and wait a few seconds.</p>
        <p>11. If the page shows that SweatDNS is activated on your network, you are ready to go!</p>
        <div className="devider" style={{border: '1px solid #333', margin: '20px 0'}}></div>
      </div>
    );
  };

  export default XboxInstructions;