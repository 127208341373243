import React from "react";
import PC1 from '../assets/img/PC1.png';
import PC2 from '../assets/img/PC2.png';
import PC3 from '../assets/img/PC3.png';
import PC4 from '../assets/img/PC4.png';
import PC5 from '../assets/img/PC5.png';
import PC6 from '../assets/img/PC6.png';
import PC7 from '../assets/img/PC7.png';
import PC8 from '../assets/img/PC8.png';

const PcInstructions = () => {
    return (
      <div className="pc-container" style={{background: '#222', maxWidth: '700px', margin: '0 auto', marginTop: '30px', padding: '20px', textAlign: 'left'}}>
        <h2 className="pc-container-header" style={{margin: '0 auto'}}>PC setup</h2>
        <p>1. Go to your "Windows search" and search for "Run" click enter.</p>
        <img className="PC1" src={PC1} alt="PC1" style={{width: '60%', margin: '0 auto', display: 'flex'}} />
        <p>2. Type "ncpa.cpl" and hit enter.</p>
        <img className="PC2" src={PC2} alt="PC2" style={{width: '60%', margin: '0 auto', display: 'flex'}} />
        <p>3. Right click on your "Network" and click "Properties".</p>
        <img className="PC3" src={PC3} alt="PC3" style={{width: '60%', margin: '0 auto', display: 'flex'}} />
        <p>4. Click on "Internet Protocol Version 4 (TCP/IPv4)" and click "Properties".</p>
        <img className="PC4" src={PC4} alt="PC4" style={{width: '60%', margin: '0 auto', display: 'flex'}} />
        <p>5. Choose a location, then copy the Primary and Secondary DNS shown under the locations to your Internet Protocol Version 4 Properties as in the image below.</p>
        <img className="PC5" src={PC5} alt="PC5" style={{width: '60%', margin: '0 auto', display: 'flex'}} />
        <p>6. Click "OK" and "Close".</p>
        <p>7. Open command prompt and type the following command "ipconfig /flushdns"</p>
        <img className="PC6" src={PC6} alt="PC6" style={{width: '60%', margin: '0 auto', display: 'flex'}} />
        <p>8. Start your game and enjoy!</p>
        <p>* If location is not updated yet, restart your PC. In case it still didn't changed your location please follow the steps from below:</p>
        <img className="PC7" src={PC7} alt="PC7" style={{width: '60%', margin: '0 auto', display: 'flex'}} />
        <p>Go to start and type: "settings".</p>
        <img className="PC8" src={PC8} alt="PC8" style={{width: '60%', margin: '0 auto', display: 'flex'}} />
        <p>Click on "ethernet" and make sure its set to "Private network".</p>
        <div className="devider" style={{border: '1px solid #333', margin: '20px 0'}}></div>
      </div>
    );
  };

  export default PcInstructions;
