const WAIT_BEFORE_READ = 2000;
const wait = duration => _ => new Promise(resolve => setTimeout(resolve, duration))

export class DnsLeakReport {
    reportId = this.generateReportId()

    async report() {
        return await this.submitLeakRequests()
            .then(wait(WAIT_BEFORE_READ))
            .then(this.readReport.bind(this))
            .catch(reason => {
                return { error: reason.toString() }
            });
    }

    async submitLeakRequests() {
        const submitReq = i => fetch(`https://${i}.${this.reportId}.bash.ws`).catch(_ => null)
        const leakRequests = [...Array(20).keys()].map((v, i) => submitReq(i + v));
        return await Promise.all(leakRequests)
    }

    async readReport() {
        const filterData = data => data.filter(d => d.type != 'ip' && d.type != 'conclusion');

        return await fetch(`https://bash.ws/dnsleak/test/${this.reportId}?json`)
            .then(response => response.json())
            .then(filterData)
    }

    generateReportId() {
        return Math.round(Math.random() * (99999999 - 10000000) + 10000000);
    }
}
