import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Singup from './pages/Singup';
import Price from './pages/Price';
import Help from './pages/Help';
import Login from './pages/Login';
import Terms from './pages/Terms';
import Policy from './pages/Policy';
import Reset from './pages/Reset';
import Account from './pages/Account';
import MyProfile from './pages/MyProfile';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Dns from './pages/Dns';
import Otp from './pages/Otp';
import Admin from './pages/AdminPage';
import ResetPage from './pages/PasswordReset';
import Invoices from './pages/Invoices';
import Checkout from './pages/Checkout';
import Success from './pages/Success';
import Faq from './pages/faq';
import Question1 from './pages/faq/1';
import Question2 from './pages/faq/2';
import Question3 from './pages/faq/3';
import Question4 from './pages/faq/4';
import Question5 from './pages/faq/5';
import Question6 from './pages/faq/6';
import Question7 from './pages/faq/7';

if (process.env.REACT_APP_ENV === 'production') {
  console.log = function() {};
  console.info = function() {};
  console.warn = function() {};
  console.error = function() {};
  console.debug = function() {};
}


function App() {
  return (

    <Router>
      <AuthProvider>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/price" element={<Price />} />
            <Route path="/help" element={<Help />} />
            <Route path="/sign-up" element={<Singup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/terms-of-service" element={<Terms />} />
            <Route path="/privacy-policy" element={<Policy />} />
            <Route path="/reset-password" element={<Reset />} />
            <Route path="/dns" element={<Dns />} />
            <Route path="/otp" element={<Otp />} />
            <Route path="/reset-password-user" element={<ResetPage />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/faq/what_is_the_best_location" element={<Question1 />} />
            <Route path="/faq/i_changed_my_location_but_it_doesnt_change_in_game" element={<Question2 />} />
            <Route path="/faq/why_can_i_reset_only_once_every_24_hours_my_network" element={<Question3 />} />
            <Route path="/faq/will_this_affect_my_ping" element={<Question4 />} />
            <Route path="/faq/does_this_work_on_my_console" element={<Question5 />} />
            <Route path="/faq/do_i_need_any_hardware_to_connect_it_to_my_console" element={<Question6 />} />
            <Route path="/faq/what_are_the_benefits_of_using_this_vpn" element={<Question7 />} />
            <Route path="/account" element={<PrivateRoute><Account /></PrivateRoute>} />
            <Route path="/my-profile" element={<PrivateRoute><MyProfile /></PrivateRoute>} />
            <Route path="/invoices" element={<PrivateRoute><Invoices /></PrivateRoute>} />
            <Route path="/checkout" element={<PrivateRoute><Checkout /></PrivateRoute>} />
            <Route path="/success" element={<PrivateRoute><Success /></PrivateRoute>} />
          </Routes>
          <Footer  />
        </div>
      </AuthProvider>
    </Router>

  );
}

export default App;
